import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import router from "./router";
import "assets/fonts/iconfont.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "assets/css/style.css";
import "assets/css/mobile.css";
Vue.use(ElementUI);
import axios from "axios";
import common from "./common/common.vue";
import swiper from "swiper";
import "swiper/swiper.min.css";
import $ from "jquery";
import { eventBus } from "@/utils/eventbus";
window.jQuery = $;
window.$ = $;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vuex);
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
Vue.config.productionTip = false;
Vue.prototype.apiUrl = common.ApiUrl; //测试地址
Vue.prototype.common = common;
Vue.prototype.$axios = axios;
Vue.prototype.userToken = "hcUh/0OrNgN8iytJXdbT7Q==";
Storage.prototype.setExpire = (key, value, expire) => {
  let obj = {
    data: value,
    time: Date.now(),
    expire: expire,
  };
  //localStorage 设置的值不能为对象,转为json字符串
  sessionStorage.setItem(key, JSON.stringify(obj));
};
Storage.prototype.getExpire = (key) => {
  let val = sessionStorage.getItem(key);
  if (!val) {
    return val;
  }
  val = JSON.parse(val);
  if (Date.now() - val.time > val.expire) {
    sessionStorage.removeItem(key);
    return null;
  }
  return val.data;
};

new Vue({
  router,
  render: (h) => h(App),
  created() {
    var that = this;
    var result = that.getToken();
    var token = "Bearer " + result.Data.access_token;
    // that.getMenu();
    $.ajaxSetup({
      beforeSend: function (request) {
        request.setRequestHeader("Authorization", token);
      },
    });
    axios.defaults.headers.common["Authorization"] = token;
  },
  methods: {
    getToken() {
      var res = JSON.parse(sessionStorage.getExpire("getToken"));
      if (!res) {
        var obj = {
          userToken: this.userToken,
          appProject: "Basic",
        };
        var centent = JSON.stringify(obj);
        var httpRequest = new XMLHttpRequest();
        httpRequest.open(
          "POST",
          this.apiUrl + "/Api/Source/GenerateJwtToken",
          false
        );
        httpRequest.setRequestHeader("Content-type", "application/json");
        httpRequest.send(centent);
        var json = httpRequest.responseText;
        sessionStorage.setExpire("getToken", json, 1000 * 60 * 60);
        res = JSON.parse(json);
      }
      return res;
    },
    // getMenu() {
    //   let oldMenu = [
    //     {
    //       name: "院士",
    //       type: "yuanshijianjie",
    //       path: "/yuanshijianjie",
    //       code: "Basic_Intro",
    //       page: "views/yuanshijianjie",
    //     },
    //     {
    //       name: "核心技术",
    //       type: "hexinjishu",
    //       path: "/hexinjishu",
    //       code: "Basic_KJCX",
    //       page: "views/kejichuangxin",
    //       lists: [
    //         {
    //           names: "高坝工程",
    //           itemIdNum: "115",
    //           typeCodeNum: "CX027",
    //         },
    //         {
    //           names: "水网工程",
    //           itemIdNum: "117",
    //           typeCodeNum: "CX029",
    //         },
    //         {
    //           names: "数字工程",
    //           itemIdNum: "119",
    //           typeCodeNum: "CX031",
    //         },
    //         {
    //           names: "新型电力系统与储能工程",
    //           itemIdNum: "118",
    //           typeCodeNum: "CX030",
    //         },
    //         {
    //           names: "其他",
    //           itemIdNum: "120",
    //           typeCodeNum: "CX032",
    //         },
    //       ],
    //     },
    //     {
    //       name: "学术成果",
    //       type: "xueshuchengguo",
    //       path: "/xueshuchengguo",
    //       code: "Basic_CXCG",
    //       page: "views/kejichengguo",
    //       lists: [
    //         {
    //           names: "专利",
    //           itemIdNum: "16",
    //           typeCodeNum: "CX005",
    //         },
    //         {
    //           names: "专著",
    //           itemIdNum: "17",
    //           typeCodeNum: "CX006",
    //         },
    //         {
    //           names: "论文",
    //           itemIdNum: "18",
    //           typeCodeNum: "CX007",
    //         },
    //         {
    //           names: "标准",
    //           itemIdNum: "19",
    //           typeCodeNum: "CX008",
    //         },
    //         {
    //           names: "软著",
    //           itemIdNum: "20",
    //           typeCodeNum: "CX009",
    //         },
    //         {
    //           names: "科研报告",
    //           itemIdNum: "21",
    //           typeCodeNum: "CX010",
    //         },
    //       ],
    //     },
    //     {
    //       name: "荣誉奖励",
    //       type: "rongyujiangli",
    //       path: "/rongyujiangli",
    //       code: "Basic_CXJL",
    //       page: "views/kejijiangli-personal",
    //       lists: [
    //         {
    //           names: "人才奖励",
    //           itemIdNum: "23",
    //           typeCodeNum: "CX012",
    //         },
    //         {
    //           names: "科技奖励",
    //           itemIdNum: "24",
    //           typeCodeNum: "CX013",
    //         },
    //         {
    //           names: "工程奖励",
    //           itemIdNum: "40",
    //           typeCodeNum: "CX040",
    //         },
    //       ],
    //     },
    //     {
    //       name: "战略咨询",
    //       type: "zhanluezixun",
    //       path: "/zhanluezixun",
    //       code: "Basic_SGRZ_66",
    //       page: "views/zhanluezixun",
    //       lists: [
    //         {
    //           names: "战略咨询",
    //           itemIdNum: "25",
    //           typeCodeNum: "CX014",
    //         },
    //       ],
    //     },
    //     {
    //       name: "工程咨询",
    //       type: "gongchengzixun",
    //       path: "/gongchengzixun",
    //       code: "Basic_SGRZ_68",
    //       page: "views/gongchengzixun",
    //       lists: [
    //         {
    //           names: "工程咨询",
    //           itemIdNum: "26",
    //           typeCodeNum: "CX015",
    //         },
    //       ],
    //     },
    //     {
    //       name: "科技项目",
    //       type: "kejixiangmu",
    //       path: "/kejixiangmu",
    //       code: "Basic_SGRZ_67",
    //       page: "views/kejigongguan",
    //       lists: [
    //         {
    //           names: "科技项目",
    //           itemIdNum: "716",
    //           typeCodeNum: "CX034",
    //         },
    //       ],
    //     },
    //     {
    //       name: "人才培养",
    //       type: "rencaipeiyang",
    //       path: "/rencaipeiyang",
    //       code: "Basic_CXRC",
    //       page: "views/rencaipeiyang",
    //       lists: [
    //         {
    //           names: "领军人才",
    //           itemIdNum: "27",
    //           typeCodeNum: "CX016",
    //         },
    //         {
    //           names: "博士后",
    //           itemIdNum: "28",
    //           typeCodeNum: "CX017",
    //         },
    //         {
    //           names: "博士",
    //           itemIdNum: "29",
    //           typeCodeNum: "CX018",
    //         },
    //         {
    //           names: "硕士",
    //           itemIdNum: "30",
    //           typeCodeNum: "CX019",
    //         },
    //       ],
    //     },
    //     {
    //       name: "创新研究院",
    //       type: "chuanxinyanjiuyuan",
    //       path: "/chuanxinyanjiuyuan",
    //       code: "Basic_CXGZZ_01",
    //       page: "views/yuanshigongzuozhan",
    //       lists: [
    //         {
    //           names: "创新研究院",
    //           itemIdNum: "42",
    //           typeCodeNum: "CX033",
    //         },
    //       ],
    //     },
    //     {
    //       name: "学术活动",
    //       type: "xueshuhuodong",
    //       path: "/xueshuhuodong",
    //       code: "Basic_CXHD_01",
    //       page: "views/xueshujiaoliu",
    //       lists: [
    //         {
    //           names: "学术活动",
    //           itemIdNum: "31",
    //           typeCodeNum: "CX020",
    //         },
    //       ],
    //     },
    //     {
    //       name: "社会活动",
    //       type: "shehuihuodong",
    //       path: "/shehuihuodong",
    //       code: "Basic_CXHD_02",
    //       page: "views/shehuihuodong",
    //       lists: [
    //         {
    //           names: "社会活动",
    //           itemIdNum: "32",
    //           typeCodeNum: "CX021",
    //         },
    //       ],
    //     },
    //   ];
    //   let menu = [];
    //   $.ajax({
    //     type: "get", //请求方式不写就默认为get请求
    //     url: this.apiUrl + "/Api/YSPTApi/GetMenu",
    //   }).then(
    //     (res) => {
    //       //数据请求成功，res为成功数据
    //       menu = res.Data.menu.map((item, index) => {
    //         var findData = oldMenu.find((i) => i.code === item.code);
    //         if (findData && findData.code == item.code) {
    //           item.path = findData.path;
    //           console.log(111);
    //           return item;
    //         }
    //       });

    //       eventBus.emit("menu", menu);
    //     },
    //     (err) => {
    //       menu = oldMenu;
    //       eventBus.emit("menu", menu);
    //     }
    //   );
    // },
  },
}).$mount("#app");
