<script>
function dateFormat(fmt, input) {
  //input为字符串
  var date = new Date(input);
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}
const contentType = [{
    name: ".jpe",
    value: "image/jpeg"
  },
  {
    name: ".jpeg",
    value: "image/jpeg"
  },
  {
    name: ".jpf",
    value: "image/jp2"
  },
  {
    name: ".jpg",
    value: "image/jpeg"
  },
  {
    name: ".png",
    value: "image/png"
  },
  {
    name: ".bmp",
    value: "image/bmp"
  },
  {
    name: ".dwg",
    value: "image/vnd.dwg"
  },
  {
    name: ".gif",
    value: "image/gif"
  },
  {
    name: ".js",
    value: "application/javascript"
  },
  {
    name: ".json",
    value: "application/json"
  },
  {
    name: ".mp2",
    value: "video/mpeg"
  },
  {
    name: ".mp3",
    value: "audio/mpeg"
  },
  {
    name: ".mp4",
    value: "video/mp4"
  },
  {
    name: ".mpeg",
    value: "video/video/mpeg"
  },
  {
    name: ".pdf",
    value: "application/pdf"
  },
  {
    name: ".ppt",
    value: "application/vnd.ms-powerpoint"
  },
  {
    name: ".pptx",
    value: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  },
  {
    name: ".doc",
    value: "application/msword"
  },
  {
    name: ".docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  },
  {
    name: ".xls",
    value: "application/vnd.ms-excel"
  },
  {
    name: ".xlsx",
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  },

]

export default {

  dateFormat,
  ApiUrl: "https://www.zhangzongliang.com/cxpt",  //生产地址
  // ApiUrl: "http://192.168.199.215:7070/admin", //测试地址
  RouterBase: "",
  //RouterBase:"/vue/"
  contentType,
};
</script>

