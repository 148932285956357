import Vue from "vue";
import Router from "vue-router";
import common from "../common/common.vue";
Vue.use(Router);

let router = new Router({
  base: common.RouterBase,
  routes: [
    {
      path: "/",
      name: "首页",
      component: () => import("views/index"),
    },
    {
      path: "/yuanshijianjie",
      name: "院士简介",
      component: () => import("views/yuanshijianjie"),
    },
    {
      path: "/hexinjishu",
      name: "核心技术",
      component: () => import("views/kejichuangxin"),
    },
    {
      path: "/xueshuchengguo",
      name: "学术成果",
      component: () => import("views/kejichengguo"),
    },
    {
      path: "/rongyujiangli",
      name: "荣誉奖励",
      component: () => import("views/kejijiangli-personal"),
    },
    {
      path: "/zhanluezixun",
      name: "战略咨询",
      component: () => import("views/zhanluezixun"),
    },
    {
      path: "/gongchengzixun",
      name: "工程咨询",
      component: () => import("views/gongchengzixun"),
    },
    {
      path: "/kejixiangmu",
      name: "科技项目",
      component: () => import("views/kejigongguan"),
    },
    {
      path: "/rencaipeiyang",
      name: "人才培养",
      component: () => import("views/rencaipeiyang"),
    },
    {
      path: "/chuanxinyanjiuyuan",
      name: "创新研究院",
      component: () => import("views/yuanshigongzuozhan"),
    },
    {
      path: "/xueshuhuodong",
      name: "学术活动",
      component: () => import("views/xueshujiaoliu"),
    },
    {
      path: "/shehuihuodong",
      name: "社会活动",
      component: () => import("views/shehuihuodong"),
    },
    {
      path: "/sousuo",
      name: "搜索",
      component: () => import("views/sousuo"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("views/index"),
    },
  ],
});
export default router;
