function EventBus() {}

let id = 0;
// var EventBus = new Vue();

EventBus.prototype.on = function (name, callback) {
  // 若没有事件对象，新加一个
  if (!this._events) {
    // 创建一个没有原型的对象
    this._events = Object.create(null);
    // 若没有这个事件的订阅，新增加一个,若有，则设置{id: callback}
  }
  if (!this._events[name]) {
    this._events[name] = {};
  }
  id += 1;
  this._events[name][id] = callback;
  return id;
};

EventBus.prototype.emit = function (name, ...args) {
  // 发布的时候，若有这个事件，循环执行所有这个订阅的方法
  if (this._events[name]) {
    Object.values(this._events[name]).forEach((callback) => {
      callback(...args);
    });
  }
};

EventBus.prototype.off = function (name, cbId) {
  let eventName = this._events[name];
  if (!eventName) {
    return;
  }
  // 若指定了回调id,清除对应的回调，否则清除所有订阅
  if (!eventName) {
    delete this._events[name];
  } else {
    delete eventName[cbId];
  }
};

EventBus.prototype.once = function (name, callback) {
  let once = (...args) => {
    callback(...args);
    this.off(name, cbId);
  };
  let cbId = this.on(name, once);
};

const eventBus = new EventBus();
export { eventBus };
